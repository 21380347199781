@use 'variables';

*,
:after,
:before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	scroll-padding-top: 7rem;
}

body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 6rem;
	font-family: 'Montserrat', sans-serif;
	background-color: variables.$color-primary;
	// overflow-x: hidden;
}

.wrapper {
	max-width: 1400px;
	width: 100%;
	margin: 0 auto;
	padding: 0 1em;
}

.underline {
	margin: 0 auto 4rem;
	width: 8rem;
	height: 3px;
	background-color: rgb(207, 25, 25);
}

.section {
	margin-top: 4rem;
	padding: 4rem 0rem;

	width: 100%;

	&__formating {
		text-transform: uppercase;
		text-align: center;
	}

	&__heading {
		margin-bottom: 1rem;
		font-size: 3rem;
		font-weight: 400;
		text-shadow: 2px -2px 5px variables.$color-black-light;
	}

	&__subheading {
		font-size: 2rem;
		margin-bottom: 4rem;
	}
}

.navbar {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: variables.$color-primary;
	padding: 0;
	z-index: 10;

	&__nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 8rem;
	}

	&__logo {
		&--img {
			height: 6rem;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: absolute;
		top: 8rem;
		right: 0;
		width: 100%;
		height: calc(100vh - 70px);
		transform: translateX(100%);
		background-color: #fff;
		opacity: 0;
		transition: transform 0.5s, opacity 0.5s;
		overflow: auto;
	}

	&__item {
		display: inline-block;
		margin-top: 1.5em;
		font-size: 2rem;
		list-style: none;
		text-transform: uppercase;

		a {
			padding: 1em 2em;
			color: #000;
			transition: color 0.3s;
			text-decoration: none;
		}
	}

	&__burger-btn {
		cursor: pointer;
		background: none;
		border: 1px solid black;
		border-radius: 0.8rem;

		.fas {
			padding: 1rem;
			font-size: 2.5rem;
		}
	}
}

.showNav {
	transform: translateX(0);
	opacity: 1;
}

.header {
	position: relative;
	width: 100%;

	&__btn-more {
		position: absolute;
		display: block;
		bottom: 30%;
		left: 50%;
		transform: translateX(-50%);
		padding: 1.5rem 4rem;
		background-color: variables.$color-secondary-light;
		border-radius: 4rem;
		font-size: 1.5rem;
		color: variables.$color-primary;
		text-transform: uppercase;
		text-decoration: none;
		box-shadow: 0 0 3rem rgba(150, 120, 120, 0.8);

		&:visited,
		&:active {
			color: variables.$color-primary;
		}
	}
}

.hero-img {
	position: relative;
	background-image: url(../img/hero-small.jpg);
	background-size: cover;
	background-position: center;
	height: 100vh;
	width: 100%;
	clip-path: polygon(0% 0%, 100% 0, 100% 95%, 0% 100%);

	&__shadow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
	}

	&__text {
		position: absolute;
		top: 40%;
		left: 5%;
		transform: translateY(-50%);
		color: variables.$color-primary;

		&--heading {
			font-size: 3.2rem;
			margin-bottom: 2.5rem;
		}

		&--description {
			font-size: 1.8rem;
		}
	}
}

.offer {
	&__container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__box {
		position: relative;
		width: 80%;
		height: 12rem;
		background-color: variables.$color-secondary-light;
		transform: skewX(-15deg);
		box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.6);

		&:not(:last-child) {
			margin-bottom: 4rem;
		}

		&--heading {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) skewX(15deg);
			text-transform: uppercase;
			font-size: 1.8rem;
			font-weight: 200;
			text-align: center;
			color: variables.$color-primary;
		}
	}
}

.products {
	position: relative;
	background-image: url(../img/milling-small.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
	z-index: -2;

	.hero-img__shadow {
		z-index: -1;
	}

	.carousel-slick {
		display: flex;
		align-items: center;
		height: 30rem;
		width: 30rem;
		margin: 0 auto;
		overflow-x: hidden;
	}

	&__description {
		margin-bottom: 4rem;
		padding: 1rem;
		font-size: 1.4rem;
	}

	&__card {
		background-color: #fff;

		&--img {
			height: 300px;
			padding: 1.2rem;
		}
	}
}

.about {
	margin-top: 1rem;

	&__description {
		margin-bottom: 4rem;
		padding: 1rem;
		font-size: 1.4rem;
	}

	.carousel-slick {
		display: flex;
		align-items: center;
		height: 20rem;
		width: 30rem;
		margin: 0 auto;
	}

	&__card {
		&--img {
			width: 100%;
			padding: 1rem;
		}
	}
}

.contact {
	position: relative;
	background-color: variables.$color-secondary-light;
	color: variables.$color-primary;
	text-align: center;

	.underline {
		background-color: variables.$color-primary;
	}

	&__logo {
		width: 80%;
		margin: 0 auto 5rem auto;
		background-color: variables.$color-primary;

		&--img {
			padding: 2rem;
			width: 100%;
		}
	}
	&__box {
		display: inline-flex;
	}

	&__data {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 3rem 4rem;
		text-align: left;
		text-transform: uppercase;
		font-size: 1.5rem;
		border: 3px solid white;

		&--fv {
			margin-bottom: 3rem;
		}

		&--text {
			margin-top: 0.2rem;
			margin-bottom: 0;
			font-weight: 200;
		}
	}

	&__heading {
		position: relative;
		text-align: center;
		width: 100%;
		text-transform: uppercase;
		font-size: 1.7rem;
		margin: 5rem 0;

		&::after {
			content: '';
			position: absolute;
			bottom: -1rem;
			left: 50%;
			width: 10rem;
			height: 2px;

			transform: translateX(-50%);
			background-color: variables.$color-primary;
		}
	}

	&__subheading {
		margin-bottom: 1.5rem;
		font-size: 1.5rem;
		text-align: center;
	}

	&__map {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 2em auto;
		margin-bottom: 4em;

		iframe {
			width: 100%;
			height: 350px;
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: -2rem;
		left: 0;
		width: 100%;
		height: 2.05rem;
		background-color: variables.$color-secondary-light;
		clip-path: polygon(0 2rem, 100% 0, 100% 100%, 0% 100%);
	}
}

.footer {
	&__text {
		padding: 2rem;
		margin: 0;
		font-size: 1.4rem;
	}
}

@media (min-width: 576px) {
	.section {
		&__heading {
			font-size: 3.5rem;
		}
	
		&__subheading {
			font-size: 2.5rem;
		}
	}

	.navbar {
		&__item {
			font-size: 2.8rem;
		}
	}

	.header {
		&__btn-more {
			font-size: 2rem;
		}
	}

	.hero-img {
		&__text {
			&--heading {
				font-size: 3.5rem;
				margin-bottom: 2.5rem;
			}

			&--description {
				font-size: 2.0rem;
			}
		}
	}

	.offer {
		&__box {
			&--heading {
				font-size: 2.2rem;
			}
		}
	}

	.products {
		&__description {
			margin-bottom: 4rem;
			padding: 1.5rem;
			font-size: 2.0rem;
		}
	}

	.about {
		&__description {
			font-size: 2.2rem;
		}
	}

	.contact {
		&__logo {
			width: 70%;
		}

		&__data {
			flex-direction: row;
			align-items: flex-start;
			padding: 2rem;

			&--fv {
				margin-bottom: 0;
				margin-right: 3rem;
			}
		}

		&__map {
			padding: 1rem;

			iframe {
				width: 100%;
				height: 50rem;
			}
		}
	}
}

@media (min-width: 768px) {
	.section {
		&__heading {
			margin-bottom: 1rem;
			font-size: 4.2rem;
		}
	
		&__subheading {
			font-size: 2.8rem;
		}
	}

	.hero-img {
		background-image: url(../img/hero-big.jpg);

		&__text {
			&--heading {
				font-size: 3.9rem;
			}

			&--description {
				font-size: 2.7rem;
			}
		}
	}

	// .header {
	// 	&__btn-more {
	// 		padding: 1.5rem 4rem;
	// 		background-color: rgba(241, 0, 0, 0.8);
	// 		border-radius: 4rem;
	// 		font-size: 1.5rem;
	// 		color: variables.$color-primary;
	// 		text-transform: uppercase;
	// 		text-decoration: none;
	// 		box-shadow: 0 0 3rem rgba(150, 120, 120, 0.8);
	// 		transition: all 0.4s;
	// 		backface-visibility: hidden;

	// 		&:visited,
	// 		&:link {
	// 			color: variables.$color-primary;
	// 			transform: translate(-50%, 0);
	// 		}

	// 		&:hover {
	// 			transform: translate(-50%, 0) scale(1.03);
	// 			background-color: variables.$color-primary;
	// 			color: variables.$color-secondary-light;
	// 			font-weight: 600;
	// 		}

	// 		&:active {
	// 			transform: translate(-50%, 0) scale(0.95);
	// 		}
	// 	}
	// }

	.offer {
		&__box {
			&--heading {
				font-size: 2.2rem;
			}
		}
	}

	.products {
		&__description {
			margin-bottom: 4rem;
			padding: 1.5rem;
			font-size: 2.0rem;
		}
	}

	.about {
		&__description {
			font-size: 2.2rem;
		}
	}

	.contact {
		&__logo {
			width: 70%;
		}

		&__data {
			flex-direction: row;
			align-items: flex-start;
			padding: 2rem;

			&--fv {
				margin-bottom: 0;
				margin-right: 3rem;
			}
		}

		&__map {
			padding: 1rem;

			iframe {
				width: 100%;
				height: 50rem;
			}
		}
	}
}

