*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  scroll-padding-top: 7rem;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

.wrapper {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
}

.underline {
  margin: 0 auto 4rem;
  width: 8rem;
  height: 3px;
  background-color: rgb(207, 25, 25);
}

.section {
  margin-top: 4rem;
  padding: 4rem 0rem;
  width: 100%;
}
.section__formating {
  text-transform: uppercase;
  text-align: center;
}
.section__heading {
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 400;
  text-shadow: 2px -2px 5px rgba(0, 0, 0, 0.6);
}
.section__subheading {
  font-size: 2rem;
  margin-bottom: 4rem;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  padding: 0;
  z-index: 10;
}
.navbar__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
}
.navbar__logo--img {
  height: 6rem;
}
.navbar__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 8rem;
  right: 0;
  width: 100%;
  height: calc(100vh - 70px);
  transform: translateX(100%);
  background-color: #fff;
  opacity: 0;
  transition: transform 0.5s, opacity 0.5s;
  overflow: auto;
}
.navbar__item {
  display: inline-block;
  margin-top: 1.5em;
  font-size: 2rem;
  list-style: none;
  text-transform: uppercase;
}
.navbar__item a {
  padding: 1em 2em;
  color: #000;
  transition: color 0.3s;
  text-decoration: none;
}
.navbar__burger-btn {
  cursor: pointer;
  background: none;
  border: 1px solid black;
  border-radius: 0.8rem;
}
.navbar__burger-btn .fas {
  padding: 1rem;
  font-size: 2.5rem;
}

.showNav {
  transform: translateX(0);
  opacity: 1;
}

.header {
  position: relative;
  width: 100%;
}
.header__btn-more {
  position: absolute;
  display: block;
  bottom: 30%;
  left: 50%;
  transform: translateX(-50%);
  padding: 1.5rem 4rem;
  background-color: rgba(210, 0, 0, 0.8);
  border-radius: 4rem;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  box-shadow: 0 0 3rem rgba(150, 120, 120, 0.8);
}
.header__btn-more:visited, .header__btn-more:active {
  color: #fff;
}

.hero-img {
  position: relative;
  background-image: url(../img/hero-small.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 0, 100% 95%, 0% 100%);
}
.hero-img__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.hero-img__text {
  position: absolute;
  top: 40%;
  left: 5%;
  transform: translateY(-50%);
  color: #fff;
}
.hero-img__text--heading {
  font-size: 3.2rem;
  margin-bottom: 2.5rem;
}
.hero-img__text--description {
  font-size: 1.8rem;
}

.offer__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.offer__box {
  position: relative;
  width: 80%;
  height: 12rem;
  background-color: rgba(210, 0, 0, 0.8);
  transform: skewX(-15deg);
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.6);
}
.offer__box:not(:last-child) {
  margin-bottom: 4rem;
}
.offer__box--heading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) skewX(15deg);
  text-transform: uppercase;
  font-size: 1.8rem;
  font-weight: 200;
  text-align: center;
  color: #fff;
}

.products {
  position: relative;
  background-image: url(../img/milling-small.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  z-index: -2;
}
.products .hero-img__shadow {
  z-index: -1;
}
.products .carousel-slick {
  display: flex;
  align-items: center;
  height: 30rem;
  width: 30rem;
  margin: 0 auto;
  overflow-x: hidden;
}
.products__description {
  margin-bottom: 4rem;
  padding: 1rem;
  font-size: 1.4rem;
}
.products__card {
  background-color: #fff;
}
.products__card--img {
  height: 300px;
  padding: 1.2rem;
}

.about {
  margin-top: 1rem;
}
.about__description {
  margin-bottom: 4rem;
  padding: 1rem;
  font-size: 1.4rem;
}
.about .carousel-slick {
  display: flex;
  align-items: center;
  height: 20rem;
  width: 30rem;
  margin: 0 auto;
}
.about__card--img {
  width: 100%;
  padding: 1rem;
}

.contact {
  position: relative;
  background-color: rgba(210, 0, 0, 0.8);
  color: #fff;
  text-align: center;
}
.contact .underline {
  background-color: #fff;
}
.contact__logo {
  width: 80%;
  margin: 0 auto 5rem auto;
  background-color: #fff;
}
.contact__logo--img {
  padding: 2rem;
  width: 100%;
}
.contact__box {
  display: inline-flex;
}
.contact__data {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 4rem;
  text-align: left;
  text-transform: uppercase;
  font-size: 1.5rem;
  border: 3px solid white;
}
.contact__data--fv {
  margin-bottom: 3rem;
}
.contact__data--text {
  margin-top: 0.2rem;
  margin-bottom: 0;
  font-weight: 200;
}
.contact__heading {
  position: relative;
  text-align: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 1.7rem;
  margin: 5rem 0;
}
.contact__heading::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 50%;
  width: 10rem;
  height: 2px;
  transform: translateX(-50%);
  background-color: #fff;
}
.contact__subheading {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  text-align: center;
}
.contact__map {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em auto;
  margin-bottom: 4em;
}
.contact__map iframe {
  width: 100%;
  height: 350px;
}
.contact::before {
  content: "";
  position: absolute;
  top: -2rem;
  left: 0;
  width: 100%;
  height: 2.05rem;
  background-color: rgba(210, 0, 0, 0.8);
  clip-path: polygon(0 2rem, 100% 0, 100% 100%, 0% 100%);
}

.footer__text {
  padding: 2rem;
  margin: 0;
  font-size: 1.4rem;
}

@media (min-width: 576px) {
  .section__heading {
    font-size: 3.5rem;
  }
  .section__subheading {
    font-size: 2.5rem;
  }
  .navbar__item {
    font-size: 2.8rem;
  }
  .header__btn-more {
    font-size: 2rem;
  }
  .hero-img__text--heading {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }
  .hero-img__text--description {
    font-size: 2rem;
  }
  .offer__box--heading {
    font-size: 2.2rem;
  }
  .products__description {
    margin-bottom: 4rem;
    padding: 1.5rem;
    font-size: 2rem;
  }
  .about__description {
    font-size: 2.2rem;
  }
  .contact__logo {
    width: 70%;
  }
  .contact__data {
    flex-direction: row;
    align-items: flex-start;
    padding: 2rem;
  }
  .contact__data--fv {
    margin-bottom: 0;
    margin-right: 3rem;
  }
  .contact__map {
    padding: 1rem;
  }
  .contact__map iframe {
    width: 100%;
    height: 50rem;
  }
}
@media (min-width: 768px) {
  .section__heading {
    margin-bottom: 1rem;
    font-size: 4.2rem;
  }
  .section__subheading {
    font-size: 2.8rem;
  }
  .hero-img {
    background-image: url(../img/hero-big.jpg);
  }
  .hero-img__text--heading {
    font-size: 3.9rem;
  }
  .hero-img__text--description {
    font-size: 2.7rem;
  }
  .offer__box--heading {
    font-size: 2.2rem;
  }
  .products__description {
    margin-bottom: 4rem;
    padding: 1.5rem;
    font-size: 2rem;
  }
  .about__description {
    font-size: 2.2rem;
  }
  .contact__logo {
    width: 70%;
  }
  .contact__data {
    flex-direction: row;
    align-items: flex-start;
    padding: 2rem;
  }
  .contact__data--fv {
    margin-bottom: 0;
    margin-right: 3rem;
  }
  .contact__map {
    padding: 1rem;
  }
  .contact__map iframe {
    width: 100%;
    height: 50rem;
  }
}